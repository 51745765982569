<template>
  <v-menu v-model="menu" bottom left offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" icon v-bind="attrs" v-on="on">
        <icon-ellipsis-vertical-sharp />
      </v-btn>
    </template>

    <v-list>
      <template v-if="canAdd">
        <v-list-item
          :disabled="disabled"
          :class="{ 'success--text': !disabled }"
          @click="onAddItem"
        >
          <v-list-item-content>
            <v-list-item-title v-text="$t('add.item')" />
          </v-list-item-content>
          <v-list-item-icon>
            <icon-add />
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          v-if="!hideReset"
          :disabled="disabled"
          :class="{ 'primary--text': !disabled }"
          @click="onResetItem"
        >
          <v-list-item-content>
            <v-list-item-title v-text="$t('reset.item')" />
          </v-list-item-content>
          <v-list-item-icon>
            <icon-refresh-cw />
          </v-list-item-icon>
        </v-list-item>
      </template>

      <v-list-item
        v-if="canDelete"
        :disabled="disabled"
        :class="{ 'error--text': !disabled }"
        @click="onDeleteItem"
      >
        <v-list-item-content>
          <v-list-item-title v-text="$t('remove.item')" />
        </v-list-item-content>
        <v-list-item-icon>
          <icon-trash />
        </v-list-item-icon>
      </v-list-item>

      <slot name="item" :disabled="disabled" :close="closeMenu" />
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ItemActions extends Vue {
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly canAdd!: boolean;
  @Prop(Boolean) readonly canDelete!: boolean;
  @Prop(Boolean) readonly hideReset!: boolean;

  menu = false;

  onAddItem() {
    this.$emit("item:add");
  }

  onDeleteItem() {
    this.$emit("item:delete");
  }

  onResetItem() {
    this.$emit("item:reset");
  }

  closeMenu() {
    this.menu = false;
  }
}
</script>
