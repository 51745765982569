<template>
  <tr>
    <table-cell
      v-for="obHead in headers"
      :key="`td-${obHead.value}`"
      :class="obHead.class"
    >
      <position-row-btn
        v-if="obHead.value === 'accounting_movement_type_id'"
        :disabled="dialog"
        :label="sMovementType"
        @open="onOpenDialog"
      />

      <template v-if="obHead.value === 'currency_id'">
        <currency-preview
          v-if="item.currency_id"
          :currency-id="item.currency_id"
          @click="onOpenDialog"
        />
        <position-row-btn v-else :disabled="dialog" @open="onOpenDialog" />
      </template>

      <product-select
        v-if="obHead.value === 'product_id'"
        v-model="item.product_id"
        hide-label
        single-line
        without-generated
        sell
        @change="onSelectProduct"
      />

      <template v-if="obHead.value === 'name' && obProduct">
        <product-preview :name="sProductName" :product="obProduct" hide-code />
      </template>

      <form-field-text
        v-if="obHead.value === 'quantity'"
        v-model="item.quantity"
        full-width
        hide-label
        input-type="number"
        required
        single-line
      />

      <unit-price-field
        v-if="obHead.value === 'price'"
        :currency-id="item.currency_id"
        :disabled="!item.currency_id || !obProduct"
        :offer="offer"
        :tax-type="obTaxType"
        :value="item.price"
        :price-with-tax="priceWithTax"
        append-label
        @price:update="onSetPrice"
      />

      <template v-if="obHead.value === 'tax_type_id'">
        <tax-type-preview v-if="obTaxType" :item="obTaxType" />
        <position-row-btn v-else :disabled="dialog" @open="onOpenDialog" />
      </template>

      <span v-if="obHead.value === 'start_at'" v-text="sStartAtFormatted" />

      <span v-if="obHead.value === 'end_at'" v-text="sEndAtFormatted" />

      <position-row-btn
        v-if="obHead.value === 'payment_term_id' && isCredit"
        :disabled="dialog"
        :label="sPaymentTerm"
        @open="onOpenDialog"
      />

      <span v-if="obHead.value === 'next_date'" v-text="sNextDate" />

      <template v-if="obHead.value === 'actions'">
        <position-row-form
          v-model="dialog"
          :item="item"
          :price-with-tax="priceWithTax"
          @change:product="onSelectProduct"
          @change:movement-type="onSelectMovementType"
          @change:payment-term="onSelectPaymentTerm"
          @change:credit="onSetCredit"
          @save="saveItem"
          @close="onClose"
        />
        <v-btn color="red lighten-2" icon text @click="onDelete">
          <icon-trash />
        </v-btn>
      </template>
    </table-cell>
  </tr>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import type {
  AccountingMovementType,
  CompanyMovementType,
  Contract,
  ContractPosition,
  Customer,
  OfferData,
  ProductGwData,
  TimePeriodData,
} from "@planetadeleste/vue-mc-gw";
import { TaxType, TimePeriod } from "@planetadeleste/vue-mc-gw";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import TableCell from "@/components/common/TableCell.vue";
import ItemActions from "@/modules/invoices/components/rows/ItemActions.vue";
import AccountingMovementTypeSelect from "@/modules/accountingentries/components/AccountingMovementTypeSelect.vue";
import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import type { Ref } from "@/types/utils";
import ProductSelect from "@/modules/products/components/ProductSelect.vue";
import { Product } from "@planetadeleste/vue-mc-shopaholic";
import ProductPreview from "@/modules/products/components/ProductPreview.vue";
import UnitPriceField from "@/modules/invoices/components/UnitPriceField.vue";
import TaxTypePreview from "@/modules/taxtypes/components/TaxTypePreview.vue";
import PaymentTermsSelect from "@/modules/paymentterms/components/PaymentTermsSelect.vue";
import type {
  TimeFrequencyCalc,
  TimePeriodFunction,
} from "@/composables/timePeriod";
import useTimePeriod from "@/composables/timePeriod";
import FormFieldDatePicker from "@/components/form/fields/DatePicker.vue";
import type { PaymentTermData } from "@planetadeleste/vue-mc-gw/src/types";
import PositionRowForm from "@/modules/contracts/components/tabs/PositionRowForm.vue";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";
import dayjs from "dayjs";
import PositionRowBtn from "@/modules/contracts/components/tabs/PositionRowBtn.vue";
import { AppModule } from "@/store/app";

@Component({
  components: {
    PositionRowBtn,
    CurrencyPreview,
    PositionRowForm,
    FormFieldDatePicker,
    PaymentTermsSelect,
    TaxTypePreview,
    UnitPriceField,
    ProductPreview,
    ProductSelect,
    CurrencySelect,
    AccountingMovementTypeSelect,
    ItemActions,
    TableCell,
  },
})
export default class PositionRow extends Vue {
  @Inject() readonly customer!: Ref<Customer>;
  @Inject() readonly contract!: Ref<Contract>;
  @Prop(Object) readonly item!: ContractPosition;
  @Prop(Array) readonly headers!: DataTableHeader[];

  dialog = false;
  isCredit = true;
  obProduct: Product | null = null;
  obTaxType: TaxType | null = null;
  obMovementType: AccountingMovementType | null = null;
  obPaymentTerm: PaymentTermData | null = null;
  obPeriodHelper: TimePeriodFunction | null = null;

  get companyMovementTypeConfig() {
    return AppModule.companyMovementTypeConfig;
  }

  get obContract(): Contract | undefined {
    return this.contract?.value;
  }

  get sPaymentTerm(): string | undefined {
    return this.obPaymentTerm?.name;
  }

  get sProductName(): string | undefined {
    return this.item?.name;
  }

  get sMovementType(): string | undefined {
    return this.obMovementType?.name;
  }

  get offer(): OfferData | undefined {
    return this.obProduct?.get("offer");
  }

  get obProductGW(): ProductGwData | undefined {
    return this.obProduct?.get("product_gw");
  }

  get obPeriod(): Partial<TimePeriodData> {
    const obModel = this.obContract ? this.obContract.get("period", {}) : {};

    return obModel instanceof TimePeriod ? obModel.attributes : obModel;
  }

  get priceWithTax(): boolean {
    if (!this.obMovementType?.invoice_movement_type_id) {
      return false;
    }

    const obCompanyMovements = this.companyMovementTypeConfig.filter(
      (obItem: CompanyMovementType) => {
        return (
          obItem.movement_type_config.invoice_movement_type_id ===
          this.obMovementType?.invoice_movement_type_id
        );
      }
    );

    if (!obCompanyMovements.length) {
      return false;
    }

    let obMovement: CompanyMovementType | undefined | null = null;
    if (obCompanyMovements.length === 1) {
      obMovement = obCompanyMovements.first() as CompanyMovementType;
    } else {
      obMovement = obCompanyMovements.find(
        (obItem: CompanyMovementType) =>
          obItem.movement_type_config.is_cash === this.obMovementType?.is_cash
      ) as CompanyMovementType;
    }

    return obMovement ? obMovement.price_with_tax : false;
  }

  get sNextDate() {
    return this.obPeriodHelper?.next;
  }

  get sStartAt() {
    return this.item.start_at;
  }

  get sStartAtFormatted(): string {
    return this.sStartAt ? dayjs(this.sStartAt).format("DD/MM/YYYY") : " - ";
  }

  get sEndAt() {
    return this.item.end_at;
  }

  get sEndAtFormatted(): string {
    return this.sEndAt ? dayjs(this.sEndAt).format("DD/MM/YYYY") : " - ";
  }

  @Watch("obPeriod", { deep: true })
  onChangePeriod(obData: TimeFrequencyCalc, obOldData?: TimeFrequencyCalc) {
    if (
      this.sStartAt &&
      obData.frequency &&
      obData.frequency_interval &&
      (!obOldData ||
        obOldData.frequency !== obData.frequency ||
        obOldData.frequency_interval !== obData.frequency_interval)
    ) {
      this.obPeriodHelper = useTimePeriod(this.sStartAt, obData);
    }
  }

  @Watch("sStartAt")
  onChangeStartAt() {
    this.onChangePeriod(this.obPeriod as TimeFrequencyCalc);
  }

  async onSelectProduct(obItem: Product) {
    this.obProduct = obItem;

    if (this.obProductGW?.taxtype_id) {
      if (
        this.item.tax_type &&
        this.item.tax_type_id == this.obProductGW.taxtype_id
      ) {
        this.obTaxType = new TaxType(this.item.tax_type);
      } else {
        this.item.set("tax_type_id", this.obProductGW.taxtype_id);
        this.obTaxType = new TaxType({ id: this.obProductGW.taxtype_id });
        await this.obTaxType.fetch();
      }
    }
  }

  onSelectMovementType(obItem: AccountingMovementType) {
    this.obMovementType = obItem;
  }

  onSelectPaymentTerm(obItem: PaymentTermData) {
    this.obPaymentTerm = obItem;
  }

  onSetCredit(bValue: boolean) {
    this.isCredit = bValue;
  }

  onSetPrice(fValue: number) {
    this.item.price = fValue;
  }

  onOpenDialog() {
    this.dialog = true;
  }

  onDelete() {
    this.$emit("remove");
  }

  saveItem(item: ContractPosition) {
    this.dialog = false;
  }

  onClose() {
    this.dialog = false;
  }

  mounted() {
    if (this.item.product && this.obProduct?.id !== this.item.product.id) {
      const obProduct =
        this.item.product instanceof Product
          ? this.item.product
          : new Product(this.item.product);
      this.onSelectProduct(obProduct);
    }

    if (this.item.payment_term) {
      this.onSelectPaymentTerm(this.item.payment_term);
    }

    if (this.item.movement_type) {
      this.onSelectMovementType(this.item.movement_type);
    }

    this.onChangePeriod(this.obPeriod as TimeFrequencyCalc);
  }
}
</script>
