<template>
  <v-btn color="primary" small text @click="onOpenDialog" :disabled="disabled">
    {{ sLabel }}
  </v-btn>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PositionRowBtn extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(Boolean) readonly disabled!: boolean;

  get sLabel() {
    return this.label || (this.$t("edit") as string);
  }

  onOpenDialog() {
    this.$emit("open");
  }
}
</script>
